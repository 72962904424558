import Cookies from 'js-cookie'

export default {
    init(){
        let re = new RegExp( "/legal/.*\\.html", "g" );
        let isPolicyPage = re.test(window.location.href); // Страница с пользовательским соглашением

        if(useGDPR){  // useGDPR определяется в шапке сайта
            let cookiesConsent = Cookies.get('cookies-consent');
            if(cookiesConsent === 'accepted'){  // юзер все разершил
                (adsbygoogle = window.adsbygoogle || []).pauseAdRequests=0;  // начали показ объявлений
            }
            else if(!isPolicyPage){  // на страницах с политиками вообще ничего не выводим
                let cookiesModal = $('#cookies-consent');
                cookiesModal.modal({
                    keyboard: false
                });
                $('#cookies-accept').click(()=>{
                    cookiesModal.modal('hide');
                    // (adsbygoogle = window.adsbygoogle || []).requestNonPersonalizedAds=1;  // неперсонализируемые объявления
                    (adsbygoogle = window.adsbygoogle || []).pauseAdRequests=0;  // начали показ объявлений
                    Cookies.set('cookies-consent', 'accepted', { expires: 365});
                });
            }
        }
    },
}