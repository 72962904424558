export default {
    fix(){
        let allPre = $('.stack-block pre');
        allPre.width('auto');
        let article = $('article:first');
        if(article){
            let widthTotal = article.width();
            let widthLeft = $('div:first', article).outerWidth();
            let widthRight = widthTotal - widthLeft;
            //console.log(`${widthTotal} = ${widthLeft} + ${widthRight}`)
            allPre.width(widthRight - 10);  // там еще 5px padding слева и справа
        }
    },
    init(){
        this.fix();
        window.onresize = this.fix;
    }
}
