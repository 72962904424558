import fixPreWidth from "./modules/fixPreWidth";
import translateToggle from "./modules/translateToggle";
import GDPR from "./modules/GDPR";

fixPreWidth.init();
translateToggle();
GDPR.init();

// infinite scroll
$('#jscroll').jscroll({
    nextSelector: 'a.jscroll-next:last',
    loadingHtml: '<div class="alert alert-light" role="alert">Loading...</div>',
});


let similarQuestions = $('#similar-questions');
if(similarQuestions.length){
    $.get(`/api/related-questions/${similarQuestions.data('id')}/`, {}, (data) => {
        similarQuestions.html(data);
    })
}