import fixPreWidth from "./fixPreWidth";


/* Перевод текста */
let trans_cache = {};

export default function () {
    // Показываем источник текста
    $('.translate-control').click(function () {
        const mongo_id = $('h1').data('id');
        const code = $(this).data('trans-code');
        let dest = $(`[data-trans-dest=${code}]`);

        if (!(code in trans_cache))
            trans_cache[code] = "";

        if (!trans_cache[code]) {
            trans_cache[code] = dest.html();
            $('span', this).removeClass('icon-translate').addClass('icon-load-c animate-spin');
            $.ajax({
                url: `/api/trans-toggle/${mongo_id}/${code}/`,
                data: {},
                type: 'get',
                error: (XMLHttpRequest, textStatus, errorThrown) => {
                    $('span', this).removeClass('icon-load-c animate-spin').addClass('icon-error');
                    $(this).attr('title', 'Load error');
                },
                success: (data) => {
                    $('span', this).removeClass('icon-load-c animate-spin').addClass('icon-translate');
                    dest.html(data);
                    fixPreWidth.fix();
                }
            });
        } else {
            let t = dest.html();
            dest.html(trans_cache[code]);
            trans_cache[code] = t;
            fixPreWidth.fix();
        }
    });
}

